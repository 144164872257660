<template>
	<!-- 贷款申请信息 start -->
	<div>
		<!-- <a-descriptions bordered :title="$t('table.applyInfo.financialLabel.loanType')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.loanType')">
				{{ $t(`table.applyInfo.financialLabel.loanMap.${loanInfo.loanType}`) }}
			</a-descriptions-item>
		</a-descriptions> -->
		<a-descriptions bordered size="default">
			<template slot="title">
				<span>{{ $t('table.applyInfo.tabsMenu.loanApply') }}</span>
				<a-button
					v-if="showEditBtn"
					:disabled="!isEditable"
					@click="setBeingRevised"
					type="primary"
					shape="circle"
					icon="form"
					style="margin-left: 20px"
				/>
				<a-button
					v-if="isShowDisbursementRecords"
					type="primary"
					style="margin-left: 10px"
					:disabled="!hasViewPermission()"
					@click="toLoanApplicationDisbursementRecords"
				>
					Disbursement Records
				</a-button>
			</template>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.loanFees')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.applyAmount) ? '' : loanInfo.applyAmount" />
			</a-descriptions-item>

			<a-descriptions-item :label="deItem.label" v-for="deItem in listItem" :key="deItem.label">
				<a-statistic :precision="2" :value="deItem.value" />
			</a-descriptions-item>
			<a-descriptions-item :label="reductions.name" v-for="reductions in loanInfo.reductions" :key="reductions.name">
				<a-statistic :precision="2" :value="$Util.isEmpty(reductions.amount) ? '' : reductions.amount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.payable')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.amountPayable) ? ' - ' : loanInfo.amountPayable" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.MonthlyInterest')">
				<span>{{ (loanInfo.interestRate * 100).toFixed(4) }}%</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.term')">
				<span>{{ loanInfo.installmentsNumbers }}</span>
			</a-descriptions-item>
			<a-descriptions-item label="Upfront Fee - Deduction">
				<show-deduction-modal :upfrontReduction="loanInfo.upfrontReduction">
					<a slot="reference">
						<a-statistic :precision="2" :value="loanInfo.upfrontReduction?.toBeDeducedUpfront" />
					</a>
				</show-deduction-modal>
			</a-descriptions-item>
			<a-descriptions-item label="Liquidation - Deduction">
				<show-deduction-modal :upfrontReduction="loanInfo.upfrontReduction">
					<a slot="reference">
						<a-statistic :precision="2" :value="loanInfo.upfrontReduction?.liquidation" />
					</a>
				</show-deduction-modal>
			</a-descriptions-item>
			<a-descriptions-item label="Other Deduction">
				<show-deduction-modal :upfrontReduction="loanInfo.upfrontReduction">
					<a slot="reference">
						<a-statistic :precision="2" :value="loanInfo.upfrontReduction?.otherDeduce" />
					</a>
				</show-deduction-modal>
			</a-descriptions-item>
			<a-descriptions-item label="Net Disbursement Amount">
				<a-statistic :precision="2" :value="loanInfo.netDisbursementAmount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.firstMonthRepayment')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.firstMonthRepay) ? 0 : Number(loanInfo.firstMonthRepay)" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.lastRepayment')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.otherMonthRepay) ? 0 : Number(loanInfo.otherMonthRepay)" />
			</a-descriptions-item>
			<a-descriptions-item v-if="isShowDisbursementRecords" label="Disbursed Amount">
				<a-statistic :precision="2" :value="disbursedAmount" />
			</a-descriptions-item>
		</a-descriptions>
	</div>
	<!-- 贷款申请信息 end -->
</template>
<script>
import { getDisbursedAmount } from '@/api/loan'
import ShowDeductionModal from '@/views/businessComponents/show-deduction-modal.vue'

export default {
	components: {
		ShowDeductionModal
	},
	props: ['loanInfo', 'loanCarInfos', 'detailData', 'showEditBtn', 'isEditable', 'parentNode'],
	data() {
		return {
			feeTypeList: [
				'loanProcessingFee',
				'creditLifeInsuranceFee',
				'crbAmount',
				'transferAmount',
				'insurancePremium',
				'engineParticulars',
				'incharge',
				'kraEntryFee',
				'maintenanceFee',
				'maintenanceFeeInstallment',
				'gpsInstallAmount',
				'gpsMonthAmount',
				'mortgageRegistrationAmount',
				'applicationFee',
				'exciseDuty'
			],
			tryCalculateCarYear: '2014',
			isShowDisbursementRecords:
				['menus.loanDataMenu.pendingDetail'].includes(this.$route.name) && ['/home/loandata/pendinglist'].includes(this.$route.query.from),
			disbursedAmount: ''
		}
	},
	computed: {
		listItem: function () {
			const listItem = []
			if (this.parentNode === 'lendingdetail') {
				for (let index = 0; index < this.feeTypeList.length; index++) {
					const deItem = this.feeTypeList[index]
					if (this.loanInfo[deItem] !== 0 && this.loanInfo[deItem] !== null) {
						listItem.push({
							label: this.$t(`table.applyInfo.loanApplyLabel.${deItem}`),
							value: this.loanInfo[deItem]
						})
					}
				}
			} else {
				for (let index = 0; index < this.$loanMap.loanType[this.loanInfo.loanType]?.descriptionsItem.length; index++) {
					const deItem = this.$loanMap.loanType[this.loanInfo.loanType]?.descriptionsItem[index]
					listItem.push({
						label:
							this.loanInfo.loanType === 1 && deItem === 'maintenanceFee'
								? this.$t('table.applyInfo.loanApplyLabel.maintenanceFee2013')
								: this.$t(
										`table.applyInfo.loanApplyLabel.${
											this.$loanMap.loanType[this.loanInfo.loanType].aliasItem.includes(deItem) ? deItem + 'Alias' : deItem
										}`
								  ),
						value: this.is2013Rule(deItem) ? this.loanInfo.maintenanceFeeInstallment : Math.round(this.loanInfo[deItem])
					})
				}
			}
			return listItem
		}
	},
	watch: {
		loanCarInfos: {
			handler: function (newValue) {
				if (newValue && newValue.length > 0) {
					const hasBefore2013 = newValue.some((i) => {
						const year = Number(i.logbook.year)
						return year > 0 && year <= 2013
					})
					if (hasBefore2013) {
						this.tryCalculateCarYear = '2013'
					} else {
						this.tryCalculateCarYear = '2014'
					}
				}
			},
			deep: true
		},
		detailData: {
			handler: async function (newValue) {
				if (newValue?.id && this.isShowDisbursementRecords) {
					this.fetchDisbursedAmount()
				}
			},
			immediate: true
		}
	},
	methods: {
		async fetchDisbursedAmount() {
			const params = {
				loanId: this.detailData.id,
				loanType: this.detailData.loanType,
				disburseLoanType: this.detailData.loanInfo.loanType
			}
			this.disbursedAmount = await getDisbursedAmount(params)
		},
		setBeingRevised() {
			this.$emit('setDataChange', {
				key: 'beingRevised',
				value: true
			})
		},
		is2013Rule(descriptionsItem) {
			return this.loanInfo.loanType === 1 && this.tryCalculateCarYear === '2013' && descriptionsItem === 'maintenanceFee'
		},
		toLoanApplicationDisbursementRecords() {
			this.$router.push({
				path: 'loanApplicationDisbursementRecords',
				query: {
					loanId: this.detailData.id,
					loanType: this.detailData.loanType,
					disburseType: this.detailData.loanInfo.disburseType || 'dtb',
					disburseLoanType: this.detailData.loanInfo.loanType
				}
			})
		},
		hasViewPermission() {
			switch (this.$route.name) {
				case 'menus.loanDataMenu.pendingDetail':
					return this.$hasPermission('ROLE_PENGING_LOAN_VIEW')
				case 'menus.loanDataMenu.renewalDetail':
					return this.$hasPermission('ROLE_RENEWAL_PENDING_VIEW')
				case 'menus.emergencyLoanMenu.detail':
					return this.$hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_VIEW')
				default:
					return false
			}
		}
	}
}
</script>
